<template>
  <div class="PaymentResponse">
    <Loader v-if="isCheckoutLoading">
      <p class="loader-message u-strong">{{ $t('common.actions.wait') }}</p>
    </Loader>
    <div >
      <Confetti />
      <md-card class="response-card">
        <md-card-header class="response-card-header">
          <div>
            <img src="@/assets/images/checkmark.png" alt="Checkmark image" class="success-image" />
          </div>
          <span class="md-title">{{ $t('paymentResponse.paymentResponseTitle') }}</span>
          <span class="md-subhead u-strong">{{ $t('paymentResponse.paymentResponSubtitle') }}</span>
        </md-card-header>

        <md-card-actions class="response-actions">
          <div class="navigate-btn">
            <md-button class="md-raised md-primary navigate-home-btn u-display-block" @click="Helpers.goToRoute('', $router)">
              {{ $t('resetPasswordView.mainPage') }}
            </md-button>
            <md-button class="md-raised navigate-account-btn u-display-block" @click="Helpers.goToRoute('my-account', $router)">
              {{ $t('resetPasswordView.myAccount') }}
            </md-button>
          </div>
        </md-card-actions>
      </md-card>
      <div class="u-justify-content-center u-text-center">
        <p class="o-default-body">{{ $t('forgotPasswordView.ifIssuesEncountered') }} {{ $t('contactEmailInreg') }}</p>
        <SecureLock class="secure-lock" />
      </div>
    </div>
    <!-- <div >
      <Confetti />
      <md-card class="response-card">
        <md-card-header class="response-card-header">
          <div class="md-title">
            <img src="@/assets/images/checkmark.png" alt="Checkmark image" class="success-image" />
          </div>
          <span class="md-title">{{ $t('paymentResponse.result.success') }}</span>
          <span class="md-subhead u-strong">{{ $t('paymentResponse.domainRegisterSuccess') }}</span>
        </md-card-header>

        <md-card-content class="response-card-content">
          <div class="purchase-details">
            <md-table class="response-content">
              <md-table-row>
                <md-table-head>{{ $t('common.name') }}</md-table-head>
                <md-table-head>{{ $t('common.quantity') }}</md-table-head>
                <md-table-head>{{ $t('common.price') }}</md-table-head>
              </md-table-row>
              <md-table-row v-for="item in this.orderDetails" :key="item.id">
                <md-table-cell>{{ item.productName }}</md-table-cell>
                <md-table-cell>{{ item.productQuantity }}</md-table-cell>
                <md-table-cell>{{ item.priceEuroNoVat.toFixed(2) }}{{ $t('cartItems.euroSign') }}</md-table-cell>
              </md-table-row>
            </md-table>
          </div>
        </md-card-content>

        <md-card-actions class="response-actions">
          <div class="navigate-btn">
            <md-button class="md-raised md-primary navigate-home-btn u-display-block" @click="Helpers.goToRoute('', $router)">
              {{ $t('resetPasswordView.mainPage') }}
            </md-button>
            <md-button class="md-raised navigate-account-btn u-display-block" @click="Helpers.goToRoute('my-account', $router)">
              {{ $t('resetPasswordView.myAccount') }}
            </md-button>
          </div>
        </md-card-actions>
        <span v-if="checkoutErrors" class="u-red u-text-center md-error">{{ $t('common.error.paymentFailed') }}</span>
      </md-card>
      <div class="u-justify-content-center u-text-center">
        <p class="o-default-body">{{ $t('forgotPasswordView.ifIssuesEncountered') }} {{ $t('contactEmailInreg') }}</p>
        <SecureLock class="secure-lock" />
      </div>
    </div>

    <div >
      <md-card class="response-card">
        <md-card-header class="response-card-header">
          <div class="md-title">
            <span class="material-icons md-36 u-red">cancel</span>
          </div>
          <span class="md-title">{{ $t('paymentResponse.result.fail') }}</span>
          <span class="md-subhead u-strong">{{ $t('paymentResponse.paymentFailedRetry') }}</span>
        </md-card-header>

        <md-card-content class="response-card-content">
          <div class="purchase-details">
            <md-table class="response-content">
              <md-table-row>
                <md-table-head>Name</md-table-head>
                <md-table-head>Quantity</md-table-head>
                <md-table-head>Price</md-table-head>
              </md-table-row>
              <md-table-row v-for="item in this.orderDetails" :key="item.id">
                <md-table-cell>{{ item.productName }}</md-table-cell>
                <md-table-cell>{{ item.productQuantity }}</md-table-cell>
                <md-table-cell>{{ item.priceEuroNoVat.toFixed(2) }}{{ $t('cartItems.euroSign') }}</md-table-cell>
              </md-table-row>
            </md-table>
          </div>
        </md-card-content>

        <md-card-actions class="response-actions">
          <div class="navigate-btn">
            <md-button class="md-raised md-primary navigate-home-btn u-display-block"
                       @click="Helpers.goToRoute('', $router)">
              {{ $t('resetPasswordView.mainPage') }}
            </md-button>
            <md-button class="md-raised md-secondary navigate-account-btn u-display-block"
                       @click="Helpers.goToRoute('my-account', $router)">
               {{ $t('resetPasswordView.myAccount') }}
            </md-button>
          </div>
        </md-card-actions>
        <span v-if="checkoutErrors" class="u-red u-text-center md-error">{{ $t('common.error.paymentFailed') }}</span>
      </md-card>
      <div class="u-justify-content-center u-text-center">
        <p class="o-default-body">{{ $t('forgotPasswordView.ifIssuesEncountered') }} {{ $t('contactEmailInreg') }}</p>
        <SecureLock class="secure-lock" />
      </div>
    </div> -->
  </div>
</template>

<script>
import Confetti from '@/components/util/Confetti.vue';
import { mapGetters } from 'vuex';
import SecureLock from '@/components/util/SecureLock';
import {
  POST_VALIDATE_CHECKOUT_ORDER
 } from '@/store/actions.type';
import {
  SET_CHECKOUT_LOADING,
  SET_SUCCESSFUL_PAYMENT,
  SET_CHECKOUT_ERROR,
  SET_RESET_CHECKOUT_ERROR
 } from '@/store/mutations.type';
import Loader from '@/components/util/Loader';

export default {
  name: 'PaymentResponse',
  components: {
    Confetti,
    Loader,
    SecureLock,
  },
  props: ['queryParams'],
  data() {
    return {
      validateDetails: [],
      orderDetails: [],
    };
  },
  computed: {
    ...mapGetters([
      'entries',
      'checkoutPaymentValidation',
      'checkoutErrors',
      'isCheckoutLoading',
      'isSuccess'
    ]),
  },
  created() {
    const payloadForPayuValidation = {
      url: window.location.href,
      ctrl: this.$route.query.ctrl,
      order: this.$route.query.order
    }

    this.$store.commit(SET_CHECKOUT_LOADING, true);

    this.$store.dispatch(POST_VALIDATE_CHECKOUT_ORDER, payloadForPayuValidation)
      .then((data) => {
        if(data.success) {
          this.$store.commit(SET_SUCCESSFUL_PAYMENT, true);
          this.$store.commit(SET_RESET_CHECKOUT_ERROR);
        } else {
          console.error('Error creating payment:', data.message);
          this.$store.commit(SET_SUCCESSFUL_PAYMENT, false);
          this.$store.commit(SET_CHECKOUT_ERROR, data.message);
        }
      })
      .catch((error) => {
        console.error('Error creating payment:', error);
        this.$store.commit(SET_SUCCESSFUL_PAYMENT, false);
        this.$store.commit(SET_CHECKOUT_ERROR, error);
      })
      .finally(() => {
        this.$store.commit(SET_CHECKOUT_LOADING, false);
      });
    }
  };
</script>

<style lang="scss" scoped>

.PaymentResponse {

  .response-card {
    display: flex;
    flex-direction: column;
    margin: 3rem auto;
    max-width: 80rem;
    padding: 6.6rem;
  }

  .response-card-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1;
    gap: 1.6rem;
    justify-content: center;
    padding-bottom: 2rem;
    padding-top: 0;
  }

  .response-content {
    width: 100%;
  }

  .response-card-content {
    flex: 1;
    padding-bottom: 0;
    text-align: center;
  }

  .response-actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .purchase-details {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
  }

  .success-image {
    height: 3.6rem;
    width: 3.6rem;
  }

  .md-field:last-child {
    margin-bottom: 2.4rem;
  }

  .secure-lock {
    margin: 2rem 0;
  }
  .navigate-btn {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
  }

  .navigate-home-btn {
    background-color: $palette-primary-color-four !important;
    border-radius: .4rem;
    box-shadow: none;
    font-family: $font-family-heading-regular;
    height: 3.8rem;
    letter-spacing: .1rem;
    min-width: 25rem;
    text-transform: none;
  }

  .navigate-account-btn {
    margin: 1.6rem;
    min-width: 25rem;
    text-transform: none;
  }
}
</style>
