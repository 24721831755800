<template>
  <div class="Confetti">
    <canvas ref="confettiCanvas" class="confetti-canvas"></canvas>
  </div>
</template>

<script>
import confetti from 'canvas-confetti';

export default {
  name: 'ConfettiComponent',
  mounted() {
    const canvas = this.$refs.confettiCanvas;

    const myConfetti = confetti.create(canvas, {
      resize: true,
      useWorker: true,
    });

    myConfetti({
      particleCount: 1400, // The number of confetti particles
      spread: 250, // How spread out the confetti is
      colors: ['#FF5733', '#33FF57', '#5733FF'], // The colors of the confetti
      shapes: ['square', 'circle'], // The shapes of the confetti
      zIndex: 1000, // z-index for the confetti
    });
  },
};
</script>

<style lang="scss" scoped>
.Confetti {

  .confetti-canvas {
    height: 100vh;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

</style>